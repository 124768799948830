import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Utils from '../../Utils/Utils';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from '../Login/Login'
import Home from '../Home/Home'
import LocalStorageServices from '../../Services/LocalStorage';
import { TOKEN, LANGUAGE } from '../../config/constants';



const { translate } = Utils;

LocalStorageServices.setItem(
  TOKEN,
'eyJraWQiOiJSOGZVMlR3RlFnQzhGUW9nWnU0ZURyeUxTVUwrUFdFRWo2U0xFb0lTZ3VrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiUDVMZkt2SXNtNHFVM3NKd1JjQUZIQSIsInN1YiI6IjNhNzQ1ZWE5LWEyMDgtNGJiOC04MTcwLTJmNjUzMjM3NzBmYyIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV82TDhBY3FXaWpfUHJpc2tvbGxUZXN0Il0sImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfNkw4QWNxV2lqIiwiY29nbml0bzp1c2VybmFtZSI6IlByaXNrb2xsVGVzdF9hc2hpc2gubmVnaUBheGZvb2Quc2UiLCJhdWQiOiIyM2lqZjZjMmJiZGZrc3A0bWMycnZyczgydSIsImlkZW50aXRpZXMiOlt7InVzZXJJZCI6ImFzaGlzaC5uZWdpQGF4Zm9vZC5zZSIsInByb3ZpZGVyTmFtZSI6IlByaXNrb2xsVGVzdCIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwczpcL1wvc3RzLndpbmRvd3MubmV0XC81NDg0NGI4OC02MWI5LTQ2ZWYtODhhZi1jYWU1YmQ4YTcwODdcLyIsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNzA4NDIxODc3MTU2In1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcxMzk2NTI0NiwiZXhwIjoxNzEzOTY4ODQ2LCJpYXQiOjE3MTM5NjUyNDYsImVtYWlsIjoiYXNoaXNoLm5lZ2lAYXhmb29kLnNlIn0.PJg_NMNKJxBtx3PLyjtVlriD5pTRgGJ7LXvSOFLwPtfUkNwE_l8wtkvPyfRhyRQhCoe-NCtNnkWFwdq4V0NdSOSrQqf8DOkj33YlA1ZX_F9JLsDg0F37p4DE2hVjThSNMQ3xICnGWMOKnzlaKHgkmoi9YQSRK98qc75Jkvn282vuNyyDB6qE98ZC43LfJuNSwYczFqWSftSjBipdHRzk45n3c8M-IFgIVbd_7BDPPDV5B1JgLgkWhisJL3xZG75avEH_HZl4579t-aCD_wE-XyUV43Os4JI5j28R3jQx8hrEPKN-W3tY03HuHl1rD0MeS1wG0NZRPqwoN_C665-pxQ'
);

if(!LocalStorageServices.getItem(LANGUAGE)){
  LocalStorageServices.setItem(LANGUAGE, 'sw')
  translate.setLanguage('sw');
}


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  // production code
  console.log = console.warn = console.error = () => { };

}

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function checkAuth() {
  return LocalStorageServices.getItem(TOKEN) ? true : false;
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        checkAuth() ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

export default App;
